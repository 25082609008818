import { template as template_89fa1618a08d4fc6906e187b02c153f4 } from "@ember/template-compiler";
const FKLabel = template_89fa1618a08d4fc6906e187b02c153f4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
