import { template as template_b2f233a3537140c381b5fe8b36077da3 } from "@ember/template-compiler";
const WelcomeHeader = template_b2f233a3537140c381b5fe8b36077da3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
