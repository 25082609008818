import { template as template_2af156ab0ca54285a23d773d20a3521c } from "@ember/template-compiler";
const FKControlMenuContainer = template_2af156ab0ca54285a23d773d20a3521c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
